import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './ControlPanel.css';
import logo_cf from './logo_cf.svg';
import logo_g2f from './logo_G2F.png';

const ControlPanel = () => {
    const [cashierList, setCashierData] = useState([]);
    const apiPort = process.env.REACT_APP_API_PORT || 7001;

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() +  '/api/cashier_status');
                const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/cashier_status' : window.location.protocol + '//' + window.location.hostname + '/api/cashier_status';
                const response = await axios.get(apiDir);

                setCashierData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        // Initial data fetch
        fetchData();

        // Set up interval for data refresh (every 500ms)
        const intervalId = setInterval(fetchData, 500);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [apiPort]);

    const changeState = (cashier) => {
        let new_status = 0;
        let new_status_str = "";
        if (cashier.status === "OPEN") {
            new_status = 3;
            new_status_str = "CERRADA";
        } else if (cashier.status === "CLOSED") {
            new_status = 1;
            new_status_str = "ABIERTA";
        }

        const fetchData = async () => {
            try {
                console.log("cashier: ", cashier);
                // await axios.put(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/update_cashier_status', {
                const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/update_cashier_status' : window.location.protocol + '//' + window.location.hostname + '/api/update_cashier_status';
                await axios.put(apiDir, {
                    "cashier": cashier.cashier,
                    "status": new_status
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

            } catch (error) {
                console.error("Error updating cashier status:", error);
            }
        };

        if (window.confirm("¿Está seguro que desea cambiar el estado de la CAJA " + cashier.cashier + " a " + new_status_str + "?")) {
            fetchData();
        }
    }

    function checkIndex(index, cantCol) {
        if (index % cantCol === 0) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className='app-container'>
            <header className="app-header">
                {/*<div><img src={logo_cf} alt="logo_cf" id="header-image-logocf" /></div>*/}
                <div className="header-text">Gestión de Cajas con IA</div>
                <div><img src={logo_g2f} alt="logo_g2f" id="header-image-logog2f"/></div>
            </header>
            <main className='app-content'>
                <h2>Estado de las Cajas (<span style={{ color: '#FF9999' }}>CERRADA</span>, <span style={{ color: '#99FF99' }}>ABIERTA</span>)</h2>
                <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
                    {cashierList.map((cashier, index) => (
                        <button key={index} className='button-cashier-status' style={{ backgroundColor: cashier.status === 'OPEN' ? '#99FF99' : '#FF9999' }} onClick={() => changeState(cashier)}>{cashier.cashier}</button>
                    ))}
                </div>
                <div className='row' style={{marginTop: "20px"}}>
                    <div className='col-md-12' style={{ textAlign: 'center' }}>
                        <a id="link_dash" href="/">Dashboard de Cajas</a>
                        <a id="link_caller" href="/caller">Caller</a>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ControlPanel;
