import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './App.css';
import VideoStream from './video-stream.js';
import logo_cf from './logo_cf.svg';
import logo_g2f from './logo_G2F.png';

function App() {
  const videoRef = useRef(null);
  const [cameraId, setCameraId] = useState('5');
  const [tableData, setTableData] = useState([]);
  const [cashierData, setCashierData] = useState([]);
  const [totalPersons, setTotalPersons] = useState(0);
  const [avgTime, setAvgTime] = useState(0);
  const [outputData, setOutputData] = useState([]);

  const apiPort = process.env.REACT_APP_API_PORT || 7001;
  const videoWsPort = process.env.REACT_APP_VIDEO_WS_PORT || 1984;

  // Connect to the video stream served by VST
//   useEffect(() => {
    // /** @type {VideoStream} */
    // const video = videoRef.current;
    // // video.src = window.location.protocol + '//' + window.location.hostname + ':' + videoWsPort.toString() + '/api/ws?src=output';
    // const wsDir = window.location.hostname === "localhost" ? "http://localhost:" + videoWsPort.toString() + '/api/ws?src=output' : window.location.protocol + '//' + window.location.hostname + '/ws?src=output';
    // video.src = wsDir;
    // video.mode = 'webrtc';
  // }, [videoWsPort]);

  // Fetch the table data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/data?camera_id=' + cameraId);
        // const response = await axios.get('http://web-api:7001/api/data?camera_id=' + cameraId);

        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/data?camera_id=' + cameraId : window.location.protocol + '//' + window.location.hostname + '/api/data?camera_id=' + cameraId;
        const response = await axios.get(apiDir);
        setTableData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, cameraId]);

  // Cashier Status
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/cashier_status?cashier=' + cameraId, 
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + '/api/cashier_status?cashier=' + cameraId, 
        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/cashier_status?cashier=' + cameraId : window.location.protocol + '//' + window.location.hostname + '/api/cashier_status?cashier=' + cameraId;
        const response = await axios.get(apiDir,
          {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
            'Content-Type': 'application/json'
          }
        }
        );
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/cashier_status?cashier=' + cameraId);
        setCashierData(response.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, cameraId]);

  // Total Persons
  useEffect(() => {
    const fetchData = async () => {
      try {
        // window.console.log("Fetching total persons data...");
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/attended_clients?camera_id=' + cameraId);
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + '/api/attended_clients?camera_id=' + cameraId);
        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/attended_clients?camera_id=' + cameraId : window.location.protocol + '//' + window.location.hostname + '/api/attended_clients?camera_id=' + cameraId;
        const response = await axios.get(apiDir);
        setTotalPersons(response.data.attended_clients);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, cameraId]);


  // Average Time
  useEffect(() => {
    const fetchData = async () => {
      try {
        // window.console.log("Fetching average time data...");
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/mean_ta?camera_id=' + cameraId);
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + '/api/mean_ta?camera_id=' + cameraId);
        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/mean_ta?camera_id=' + cameraId : window.location.protocol + '//' + window.location.hostname + '/api/mean_ta?camera_id=' + cameraId;
        const response = await axios.get(apiDir);
        setAvgTime(response.data.mean_ta);
        // window.console.log("Avg Time data:",response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, cameraId]);


  // Output Table
  useEffect(() => {
    const fetchData = async () => {
      try {
        // window.console.log("Fetching ouput data...");
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/processed_data?camera_id=' + cameraId);
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + '/api/processed_data?camera_id=' + cameraId);
        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/processed_data?camera_id=' + cameraId : window.location.protocol + '//' + window.location.hostname + '/api/processed_data?camera_id=' + cameraId;
        const response = await axios.get(apiDir);
        setOutputData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, cameraId]);

  return (
    <div className="app-container">
      <header className="app-header">
        <div className='row justify-content-center'>
          {/* <div className='col-md-2 d-flex justify-content-center align-items-center'><img src={logo_cf} alt="logo_cf" id="header-image-logocf" /></div>*/}
          <div className='col-md-8 d-flex align-items-center'><h1 className='header-text'>Gestión de Cajas con IA</h1></div>
          <div className='col-md-2 d-flex align-items-center'><img src={logo_g2f} alt="logo_g2f" id="header-image-logog2f"/></div>
        </div>
      </header>

      <main className="app-content">
        <div className="row justify-content-center">
          <div className="col-md-8">
            {/*
            <div id="stream1" className="video-div">
              <video-stream id="video1" ref={videoRef} autoplay></video-stream>
            </div>
            */}
            <div className="button-container">
              <button onClick={() => setCameraId('5')} style={{backgroundColor: cameraId==='5' ? '#fc8d2c': '#04105b'}}>Caja 5</button>
              <button onClick={() => setCameraId('6')} style={{backgroundColor: cameraId==='6' ? '#fc8d2c': '#04105b'}}>Caja 6</button>
              <button onClick={() => setCameraId('7')} style={{backgroundColor: cameraId==='7' ? '#fc8d2c': '#04105b'}}>Caja 7</button>
              <a href="/control_panel">Control de Cajas</a>
              <a href="/caller">Caller</a>
            </div>
          {/*</div>*/}

          {/*<div className="col-md-6">*/}
            <h2>Detalle Caja {cameraId}</h2>

            <div className="row">
              <div className="col-md-4">
                {cashierData.status === 'OPEN' && <p className='text'>Estado: <span className="result-text" style={{ color: 'green' }}>ABIERTA</span></p>}
                {cashierData.status === 'PAUSED' && <p className='text'>Estado: <span className="result-text" style={{ color: 'orange' }}>PAUSA</span></p>}
                {cashierData.status === 'CLOSED' && <p className='text'>Estado: <span className="result-text" style={{ color: 'red' }}>CERRADA</span></p>}
              </div>

              <div className="col-md-4">
                {cashierData.new_client === 0 && <p className='text'>Llamador: <span className="result-text">--</span></p>}
                {cashierData.new_client === 1 && <p className='text'>Llamador: <span className="result-text" style={{ color: 'green' }}>ACTIVO</span></p>}
              </div>

              <div className='col-md-4'>
                <p className="text">Clientes Atendidos: <span className='result-text' style={{color: 'green'}}>{totalPersons}</span></p>
              </div>

              <div className='col-md-4'>
                <p className='text'>T.A. Prom: {avgTime !== null && <span className='result-text'  style={{color: 'green'}}>{avgTime.toFixed(0)} s</span>}</p>
              </div>
            </div>

            <div className="row">
              <div>
                <h4>Tiempos de Atención</h4>
                <div style={{ minHeight: '200px', maxHeight: '200px', overflowY: 'auto' }}>
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Actualizado</th>
                        <th>Tiempo</th>
                        <th>ROI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.object_id}</td>
                          <td>{row.last_timestamp}</td>
                          <td>{row.ta_sec}</td>
                          <td>{row.roi}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <h4>Datos Procesados</h4>
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Timestamp</th>
                        <th>Ids</th>
                        <th>Personas</th>
                        <th>Clientes</th>
                        <th>Atención</th>
                      </tr>
                    </thead>
                    <tbody>
                      {outputData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.timestamp}</td>
                          <td>{row.ids}</td>
                          <td>{row.persons}</td>
                          <td>{row.clients}</td>
                          <td>{row.ta}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
